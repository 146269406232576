@font-face {
  font-family: 'Roboto Mono';
  src: url('fonts/Roboto_Mono/static/RobotoMono-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Tangerine';
  src: url('fonts/Tangerine/Tangerine-Bold.ttf') format('truetype');
  font-display: swap;
}

:root {
  --font-family: 'Montserrat', sans-serif;
  --font-Tangerine: 'Tangerine', serif;
  --gradient-text: #F5ECCD;
  --font-bold: 'Montserrat Bold';
}

