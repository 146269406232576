.ecrits {
    background-color: #F5ECCD; /* Replace #FF0000 with your desired background color */
  }

.article-container  h7 {
  font-family: var(--font-family);
  font-size: small;
  text-align:justify;
  align-items: flex-start;
  

  
}

.h6-group {
  margin-bottom: 50px;
}