.article-header {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45); /* Adjust the overlay color and opacity as desired */
}

.article-header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: var(--font-Tangerine);
  
  font-weight: 100;
  font-size: 75.78px;
  line-height: 90px;
  text-align: center;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .article-header {
    height: 200px;
  }

  .article-header h1 {
    font-weight: 100;
    font-size: 56.85px;
    line-height: 1.3;
    margin-top: 5px;
  }
}
@media screen and (max-width: 593px) {
  .article-header {
    height: 200px;
  }

  .article-header h1 {
    font-weight: 100;
    font-size: 42px;
    line-height: 1.3;
  }
}
@media screen and (max-width: 372px) {
  .article-header {
    height: 200px;
  }

  .article-header h1 {
    font-weight: 100;
    font-size: 40px;
    line-height: 1.3;
  }
}