.carousel-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  .carousel-wrapper .carousel .slide img {
    width: 100%;
    height: 380px;
    object-fit: cover;
  }
  
  .carousel-wrapper .carousel .slide {
    background-color: #050505; /* Set a background color for the slides */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel-wrapper .carousel .slide .image-wrapper {
    display: flex;
    flex-direction: row;
  }
  
  .carousel-wrapper .carousel .control-arrow {
    font-size: 24px;
    color: #333; /* Set the color of the arrow icons */
    background-color: rgba(0, 0, 0, 0.7); /* Set a background color for the arrow icons */
  }
  
  .carousel-wrapper .carousel .control-dots .dot {
    background-color: rgb(0, 0, 0); /* Set the color of the dot indicators */
  }
  
  .carousel-wrapper .carousel .control-dots .dot.selected {
    background-color: rgb(0, 0, 0); /* Set the color of the active dot indicator */
  }
  @media screen and (max-width: 414px){
  .carousel-wrapper .carousel .control-arrow {
    padding: 2px;
  }
  }