.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F5ECCD;
    
    
  }
  .imageagathe {
    flex: 1;
    border-radius: 10px;
    margin: 0 auto; /* Center horizontally */
    display: block; /* Ensure it's a block-level element for margin auto to work */
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .image-container {
    position: relative;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
 
  }


  .about__title {
    margin-bottom: 20px;
    font-family: var(--font-Tangerine);
    font-weight: 600;
    font-size: 56.85px;
    line-height: 1.75;
    text-align: center;
    position: relative;
   
  }

  
  .about__content {
    max-width: 800px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;


  }
  .about__content h1{
    
    font-family: var(--font-Tangerine);
    font-size: 75.78px;
    text-align: center;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
    color: #2B50AA;
    
  }
  .label {
  font-family: var(--font-family);
  font-size: small;
  text-align:center;
  align-items: center;
  font-weight: 100;
  font-style: italic;
  padding: 5px;
  display: block;
  }
  
  .about__description {
    font-size: 20px;
    line-height: 33px;
    color: #00241B;
    margin-bottom: 0px;
    font-family: var(--font-family);
    text-align: justify;
    font-weight: 600;
   
  }
  #mobile-editors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }
  #mobile-adress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0px;
    margin-top: 40px;
  }


  .icon-wrapper {
    display: flex;
    justify-content: center;
    color: #2B50AA;
   
  }
  .colored-icon {
    color: blue;
  }
  .info{
    padding: 0px;
    width:200;
    height:300;
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-style: italic;
    font-weight: 800;
    line-height: 41px;
    font-family: var(--font-family);
    color: #2B50AA;
  }
  .icon {
  width: 225px;
  color: #2B50AA;
  }
 .about__content span {
  font-size: 40px;
  padding: 20px;
 }
 @media screen and (max-width: 619px){
  .about__content span {
    font-size: 40px;
    padding: 20px;
   }
  .about__content h1{
    
    font-family: var(--font-Tangerine);
    font-size: 43px;
    text-align: center;
    font-weight: 600;
    line-height: 78px;
    margin-bottom: 30px;
  }
 }
 @media screen and (max-width: 414px) {


  .about__title {
    margin-bottom: 30px;
    font-family: var(--font-Tangerine);
    font-weight: 600;
    font-size: 56.85px;
    line-height: 1.75;
    text-align: center;
    position: relative;
  }

  
  .about__content {
    max-width: 800px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.209);
    
    
  }
  .about__content h1{
    
    font-family: var(--font-Tangerine);
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    line-height: 78px;
    
  }
  .about__content span {
    font-size: 23px;
    padding: 3px;
   }
   .about {
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center;
    background-color: #F5ECCD;
    
    padding: 20px;
    
  }
  .about__description {
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    font-weight: 800;
    font-family: var(--font-family);
    text-align: justify;
    margin-top: 10px;
   
  }
  .info{
    padding: 0px;
    width:200;
    height:300;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 41px;
    font-family: var(--font-family);
  }
  #mobile-adress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0px;
    margin-top: 30px;
  }

 }
 @media screen and (max-width: 400px) {


  .about__title {
    margin-bottom: 30px;
    font-family: var(--font-Tangerine);
    font-weight: 600;
    font-size: 56.85px;
    line-height: 1.75;
    text-align: center;
    position: relative;
  }

  
  .about__content {
    max-width: 800px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    
  }
  .about__content h1{
    
    font-family: var(--font-Tangerine);
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 30px;
  }
  .about__content span {
    font-size: 21px;
    padding: 3px;
   }
   .about {
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center;
    background-color: #F5ECCD;
    
    padding: 20px;
    
  }
  .about__description {
    font-size: 16px;
    line-height: 27px;
    
  
    font-family: var(--font-family);
    text-align: justify;

   
  }
  .info{
    padding: 0px;
    width:200;
    height:300;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-style: italic;
   
    line-height: 41px;
    font-family: var(--font-family);
  }
 }
 .about__content p {
  padding: 15px;
 }