.cards {
  position: relative; /* Add positioning to the cards */
  z-index: 0; /* Set a lower z-index to keep them behind */
  
  margin: 0;
  overflow: hidden;
  
}



.cards h1 {
  

  font-family: var(--font-);
  font-weight: 600;
  
  line-height: 60px;
  text-align: center;
  position: relative;
  align-items: center;
  font-family: "Roboto Slab", serif;
  font-size: 37px;
  
  
  letter-spacing: 1px;
  color: #2B50AA;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-right: 50%;
  margin-left: 50%;
  margin-bottom: 3%;
  margin-top: 1%;
}

.cards span {
  font-size: 40px;
  padding:10px;
}



.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.cards__wrapper {
  position: relative;
  margin:  0px 0px 0px;
  overflow:hidden;
}

.cards__wrapper_1 {
  position: relative;
  margin: 0px;
  overflow:hidden;
}

.cards__item {
  display: flex;
  
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  height: 320px;
  width: 500px;
  
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 60%;
  overflow:hidden;
  
}



.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  backface-visibility: hidden; /* Add backface-visibility property */
  overflow:hidden;
}




.cards__item__link {
  display: flex;
  
  
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
  
}

.cards__item__text {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 25%;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.517); /* Optional: Add background color to make text more visible */
  font-weight: 200;
  font-family: var(--font-family);
  overflow:hidden;
  line-height: 1.5rem;
 
  /* Safari-specific styles */
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    height: 20%; /* Adjust the height as needed for Safari */
  }

}

.cards__item__pic-wrap:hover .cards__item__text {
  opacity: 1;
  pointer-events: auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
} 
@media only screen and (max-width: 4000px) {
  .content__blog__container {
    width: 100%;
  }
  .cards__items {
    display: flex;
  }
.cards h1 {  
  font-family: var(--font-);
  
  line-height: 60px;
  text-align: center;
  position: relative;
  font-family: "Roboto Slab", serif;
  font-size: 37px;
  
  
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-right: 35%;
  margin-left: 35%;

}
}
  @media only screen and (max-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
    .cards__items {
      display: flex;
    }
  .cards h1 {  
    font-family: var(--font-);
    
    line-height: 60px;
    text-align: center;
    position: relative;
    font-family: "Roboto Slab", serif;
    font-size: 37px;
    
    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 35%;
    margin-left: 35%;
  
  }
}
  
  @media only screen and (max-width: 1080px) {
    .cards__items {
      display: flex;
    }
    .cards h1 {
   
      line-height: 60px;
    text-align: center;
    position: relative;
    font-family: "Roboto Slab", serif;
    font-size: 37px;
    
  
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.209);

      margin-right: 30%;
      margin-left: 30%;
    
      
    }
  }
  

  @media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 0rem;
    }
    .cards h1 {
   
    
      font-family: var(--font-);
    
      
      line-height: 60px;
      text-align: center;
      position: relative;
      
      font-family: "Roboto Slab", serif;
      font-size: 37px;
      
      
      
    
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      margin-right: 30%;
      margin-left: 30%;
    
      
    }
}
@media screen and (max-width: 880px) {
  .cards h1 {
    font-size: 29px;
    line-height: 49px;
   
    
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 25%;
    margin-left: 25%;
   
   
}
.gpt3__header-content h2 {
  font-size: 29px;
  line-height: 49px;
}
    
}
@media screen and (max-width: 730px) {
  .cards h1 {
    font-size: 29px;
    line-height: 49px;
    text-align: center;
    
   
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 20%;
    margin-left: 20%;
   
    
}
.cards span {
  font-size: 30px;
  padding:3px;
}
.cards__items {
  display: flex;
  flex: 1;
  border-radius: 10px;                     
  display: grid;
}
.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
}
.cards__item__text {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 25%;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.517); /* Optional: Add background color to make text more visible */
  font-weight: 200;
  font-family: var(--font-family);
  overflow:hidden;
  line-height: 1.5rem;
 
  /* Safari-specific styles */
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    height: 20%; /* Adjust the height as needed for Safari */
  }

}
.cards__wrapper {
  position: relative;
  margin: 30px 0px 0px;
}
.cards__items1{
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 340px;

  }
.cards__itemscosi{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 340px;
    
  }

}

@media screen and (max-width: 565px){
  .gpt3__header-content h2 {
    font-size: 23px;
    line-height: 40px;
 
  }
  .cards h1 {

    margin-right: 15%;
    margin-left: 15%;
  
   
}
}

@media screen and (max-width: 445px) {

.gpt3__header-content h2 {
  font-size: 23px;
  line-height: 40px;
}
.cards__items {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  display: grid;
}
.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
}

.cards__wrapper {
  position: relative;
  margin: 30px 0px 0px;
}
.cards__items1{
  display: flex;
  
  
  width: 340px;
 

  }
.cards__itemscosi{
    display: flex;
    flex: 1;
   
    width: 340px;
  
    
  }
  .cards h1 {
    font-size: 23px;
    line-height: 40px;
    text-align: center;
   
   
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 15%;
    margin-left: 15%;
  
   
}

}
@media screen and (max-width: 370px) {

  .gpt3__header-content h2 {
      font-size: 16px;
      line-height: 27px;
  }
  .gpt3__header-content p {
    font-size: 16px;
    line-height: 27px;
}
}
@media screen and (max-width: 320px) {
  .cards__items1{
    display: flex;  
    width: 280px;
    }
    .cards__itemscosi{
      display: flex;
      
      width: 280px;
     
    
      }
      
    .cards h1 {
      font-size: 23px;
      line-height: 40px;
      text-align: center;
     
     
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      margin-right: 10%;
      margin-left: 10%;
    
  }
  .cards__item__text {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 100%;
    padding: 2px;
    color: #ffffff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.517); /* Optional: Add background color to make text more visible */
    font-weight: 200;
    font-family: var(--font-family);
    overflow:hidden;
    line-height: 1.5rem;
    font-size: small;
    /* Safari-specific styles */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
      height: 20%; /* Adjust the height as needed for Safari */
    }
  
  }
}
@media screen and (max-width: 280px) {
  .cards__items1{
    display: flex;
    width: 240px;
   
  
    }

    .cards__itemscosi{
      display: flex;
      
      width: 240px;
     
    
      }
    .cards__item__pic-wrap {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow:hidden;
      
    }
    .cards h1 {
      font-size: 23px;
      line-height: 40px;
      text-align: center;
      
     
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      margin-right: 10%;
      margin-left: 10%;
    
  }
  .cards__item__text {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 100%;
    padding: 2px;
    color: #ffffff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.517); /* Optional: Add background color to make text more visible */
    font-weight: 200;
    font-family: var(--font-family);
    overflow:hidden;
    line-height: 1.5rem;
    font-size: small;
    /* Safari-specific styles */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
      height: 20%; /* Adjust the height as needed for Safari */
    }
  
  }
}