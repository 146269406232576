.article-container {
    position: relative; /* Add positioning to the container */
    background-color: #F5ECCD;
    padding: 20px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 33px;
    color:#000000;
    text-align:justify;
    margin-bottom: 20px;
    
    
    
  }
  .magazine {
    display: flex;
    
    left: 100px;
    font-style: italic;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    font-family: var(--font-body);
    
  }
  .article-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .article-paragraph {
    margin-bottom: 20px;
    margin-top: 100px; /* add margin-top to create space above the paragraphs */
   
  }
  
  .article-quote {
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .article-source {
    font-style: italic;
    color: #666;
    margin-top: 10px; /* add margin-top to create space above the source attribution */
  }
  
  .article-container p {
    margin-bottom: 30px;
    margin-top: 30px;
    font-style: bold;
    
  }
  .article-container h1 {
    margin-bottom: 50px;
  }
  .article-container h3 {
    margin-bottom: 40px;
    font-weight: 400;
    line-height: 55px;
    font-size: 37px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    font-family: var(--font-family);
    /* font-family: "Roboto Slab", serif; */
  }
  
  .article-content {
    margin-left: 50px;
    margin-right: 50px;
    
    max-width: auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .article-container p:first-of-type::first-letter {
    float: left;
    font-size: 52px;
    line-height: 0.70;
    padding-top: 8px;
   
    margin-top: -20.65px;
    font-weight: 400;
    font-family: var(--font-family);
  }
  .article-container p:first-of-type {
    text-indent: 1px; /* Adjust the value as needed */
  }
  .article-container p:last-of-type {
    margin-bottom: 80px;
  }
  .author {
    position: absolute; /* Position the author element */
    bottom: -15px; /* Adjust the value to set the desired distance from the bottom */
    right: 100px; /* Adjust the value to set the desired distance from the right */
    
    color: #2B50AA;

    font-style: bold;
  }
  .article-container h2 {
    margin-bottom: 40px;
    font-weight: 350;
    line-height: 48px;
    font-size: 32px;
    font-style: italic;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center; 
    font-family: var(--font-family);
  }
  .article-container h6{
    
    position: relative; /* Add positioning to the container */
    background-color: #f5eccd69;
    padding: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 33px;
    color:#ff0000;
    text-align:justify;
    margin-bottom: 40px;
    border-radius: 8px;
    font-style: italic;
    
  }
  .article-container h8 {

    font-weight: 400;
    line-height: 45px;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center; 
    font-family: var(--font-family);
  }


  .article-container label  {
    /* Apply the desired styles to paragraphs after the legend element */
    /* Example styles: */
    
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center; 
    text-align: center;
    line-height: 27px;
    margin-bottom: 20px;
    margin-left: 100px; /* Sets margin of 10 pixels for the left side */
    margin-right: 100px; /* Sets margin of 10 pixels for the right side */
    /* Add or modify any other styles you want to apply */
  }
  .article-container img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  #im1 {
    float:right;
   
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 60%;
    height: auto;
    margin-bottom: 0px;
    border-radius: 5px;
  }

  @media screen and (max-width: 768px) {
    .article-container h1 {
        
        line-height: 1.75;
    }

    .article-container p {
        font-size: 23px;
        line-height: 35px;
    }
    .article-container h6{
      font-size: 23px;
      line-height: 35px;
    }
 

  .article-container label {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: auto;
    align-items: center; 
    text-align: center;
    line-height: 23px;
    margin-bottom: 20px;
    margin-left: 0px; /* Sets margin of 10 pixels for the left side */
    margin-right: 0px;
}  
.article-container img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}
.article-content {
  margin-left: 30px;
  margin-right: 30px;
  
  max-width: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.article-container h8 {

  font-weight: 400;
  line-height: 45px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; 
  font-family: var(--font-family);
}
}
@media screen and (max-width: 530px) {
  .article-content{
    margin:8px;
  }
  .article-container h1 {
        
    line-height: 1.75;
}
.article-container p:first-of-type::first-letter {
  float: left;
  font-size: 42.65px;
  line-height: 0.70;
  padding-top: 8px;
 
  margin-top: -19px;
  font-weight: 400;
  font-family: var(--font-family);
}
.article-container{
  padding: 10px;
}
.article-container p {
    font-size: 16px;
    line-height: 27px;
}


.article-container label {
font-size: 13px;
display: flex;
flex-direction: column;
justify-content: auto;
align-items: center; 
text-align: center;
line-height: 23px;
margin-bottom: 20px;
margin-left: 0px; /* Sets margin of 10 pixels for the left side */
margin-right: 0px;
} 
.article-container img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.article-container h2 {
  font-weight: 800;
  margin-bottom: 50px;
  font-weight: 350;
  line-height: 36px;
  font-size: 24px;
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: auto;
  align-items: center; 
  font-family: var(--font-family);
}
.article-container h6{

  font-size: 16px;
  line-height: 27px;
}
.article-content {
  margin-left: 20px;
  margin-right: 20px;
  
  max-width: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.article-container h3 {

  font-weight: 400;
  line-height: 44px;
  font-size: 29px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; 
  font-family: var(--font-family);
}
.article-container h8 {

  font-weight: 400;
  line-height: 27px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; 
  font-family: var(--font-family);
}
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media screen and (max-width: 445px) {
  .article-container h3 {

    font-weight: 400;
    line-height: 34px;
    font-size: 23px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    font-family: var(--font-family);
  }
}
