.gpt3__navbar {
    display: flex;
    background-color: #F5ECCD;
    padding: 10px;
    position: relative; /* Add positioning to the navbar */
    z-index: 1; /* Set a higher z-index to bring it in front */
    box-sizing: border-box;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

}

.gpt3__navbar-links a:hover {
    color: #2B50AA;
}




.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 1;
    box-sizing: border-box;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container li,
.gpt3__navbar-sign li,
.gpt3__navbar-menu_container li {
    color:#00241B;
    font-family: var(--font-Tangerine);
    font-weight: 1200;
    font-style: bold;
    font-size: 60px;
    line-height: 94px;
    
    list-style-type: none;
    

    margin:0rem 1rem 0rem 0rem ;
    cursor: pointer;
}
.gpt3__navbar-menu_container a:hover {
    color:  #2B50AA;
}

.gpt3__navbar-menu {
    margin-left: 1rem;
    
    display: none;
    position: center;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    box-sizing: border-box;
    text-align: end;
    background: #F5ECCD;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 20px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.504);
}



.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1250px) {
    .gpt3__navbar-links_container li,
    .gpt3__navbar-sign li,
    .gpt3__navbar-menu_container li {
        font-size: 58px;
        line-height: 63px;
        
    }

    .gpt3__navbar {
        padding: 2rem 2rem;
    }

    .gpt3__navbar-menu_container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        z-index: 1;
        text-align: end;
       
        padding: 1.5rem;
        position: fixed;
        right: 0;
        
        margin-top: 20px;
        min-width: 150px;
      
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
}
@media screen and (max-width: 1213px) {
    .gpt3__navbar {
        padding: 1rem 0rem;
    }
    .gpt3__navbar-links_container li,
    .gpt3__navbar-sign li,
    .gpt3__navbar-menu_container li {
        font-size: 55px;
        line-height: 60px;
        
    }

}
@media screen and (max-width: 1150px) {
    .gpt3__navbar {
        padding: 1rem 0rem;
    }
    .gpt3__navbar-links_container li,
    .gpt3__navbar-sign li,
    .gpt3__navbar-menu_container li {
        font-size: 53px;
        line-height: 63px;
        
    }

}
@media screen and (max-width: 1040px) {

    .gpt3__navbar-links_container li,
    .gpt3__navbar-sign li,
    .gpt3__navbar-menu_container li {
        font-size: 50px;
        line-height: 63px;
        
    }

}

@media screen and (max-width: 986px) {
    .gpt3__navbar {
        padding: 2rem 2rem;
    }
    .gpt3__navbar-menu_container li {
        font-size: 50px;
        line-height: 60px;
        
    }
    .gpt3__navbar-links_container {
        display: none;
    }
    .gpt3__navbar-menu {
        display: flex;
    }
    .gpt3__navbar-menu_container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        z-index: 1;
        text-align: end;
       
        padding: 1.5rem;
        position: fixed;
        right: 0;
        
        margin-top: 20px;
        min-width: 150px;
      
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.505);
    }
}
@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 2rem;
    }
    .gpt3__navbar-menu_container li {
        font-size: 50px;
        line-height: 63px;
       
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 1.5rem 1.5rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }


    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
    .gpt3__navbar-menu_container li {
        color: #00241B;
        font-family: var(--font-Tangerine);
        font-weight: 400;
        font-size: 50px;
        line-height: 63px;
        
        list-style-type: none;
        
    
       
        cursor: pointer;
    }

}