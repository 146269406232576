.gpt3__header {
    display: flex;
    flex-direction: column;
    background-color:#F5ECCD;
    justify-content: flex-start;
    
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.209);
    margin-right: 3%;
    margin-left: 3%;
    margin-bottom: 2%;
    padding-bottom: 8px;

}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}

.gpt3__header-content h1 {
    font-family: var(--font-Tangerine);
    font-weight: 700;
    font-size: 100px;
    line-height: 160px;
    margin-top: 0px; /* Add top margin for whitespace */
    margin-bottom: 0px; /* Add bottom margin for whitespace */
    text-align: center;
    position: relative;
    
    font-display: swap;
}


.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 29px;
    line-height: 44px;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 0rem;
    text-align: justify;
    margin-left: 100px;
    margin-right: 100px;
    font-style: bold;
}
.gpt3__header-content h2 {
    font-family: "Roboto Slab", serif;
    font-weight: 600;
    font-size: 37px;
    line-height: 60px;
    color: #2B50AA;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 0rem;
    }
    .gpt3__header-content p {
        font-family: var(--font-family);
        
        font-size: 23px;
        line-height: 35px;
        
        margin-top: 5px;
        margin-bottom: 0rem;
        text-align: justify;
        margin-left: 100px;
        margin-right: 100px;
    }
}
@media screen and (max-width: 880px) {

    .gpt3__header-content p {
        font-size: 23px;
        line-height: 35px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .musicologue h2 {
        font-size: 23px;
        line-height: 49px;
    }
    
    
}
@media screen and (max-width: 730px) {

    .gpt3__header-content h1 {
        font-size: 97px;
        line-height: 1.75;
    }

    .gpt3__header-content p {
        font-size: 18px;
        line-height: 27px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .gpt3__header-content h2 {
        font-size: 23px;
        line-height: 49px;
    }
    .gpt3__header {
        display: flex;
        flex-direction: column;
        background-color:#F5ECCD;
        justify-content: flex-start;
        
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        margin-right: 3%;
        margin-left: 3%;
        margin-bottom: 3%;
        padding-bottom: 8px;
    
    }
    
}

@media screen and (max-width: 565px) {
    .gpt3__header-content h1 {
        font-size: 75.78px;
        line-height: 1.75;
    }

    .gpt3__header-content p {
        font-size: 18px;
        line-height: 27px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .gpt3__header h2 {
        font-size: 16px;
        line-height: 27px;
    }
    .gpt3__header{
        margin-bottom: 3%;
    }
}
@media screen and (max-width: 445px) {
    .gpt3__header {
        flex-direction: column;
    }
  
    .gpt3__header-content h1 {
        font-size: 59px;
        line-height: 1.75;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 27px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .gpt3__header-content h2 {
        font-size: 16px;
        line-height: 27px;
    }
    .gpt3__header{
        margin-bottom: 3%;
    }
}
@media screen and (max-width: 370px) {
    .gpt3__header {
        flex-direction: column;
    }
  
    .gpt3__header-content h1 {
        font-size: 46px;
        line-height: 1.75;
    }
    .gpt3__header-content h2 {
        font-size: 16px;
        line-height: 27px;
    }
}